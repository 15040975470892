import * as React from 'react'
//import { Link } from "gatsby"
import Layout from '/src/components/layout'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faLanguage, faPlug, faQuestionCircle, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons'

const LaddskolaFragorSvarPage = () => {
    return (
        <Layout pageTitle="Frågor &amp; svar om elbilsladdning">

            <p class="intro">Här nedan följer exempel på några vanliga frågor och svar för livet med elbil. <b>För support kring din elbil vänligen kontakta din tillverkare/återförsäljare.</b> Det finns även en mängd Facebook-gruppen med hjälpsamma elbilsförare.</p>

            <h2>Hur snabbt kan jag ladda?</h2>
            <p>Hur snabbt, dvs. med vilken effekt, du kan ladda beror på flera faktorer och kan även påverkas av väderförhållande såsom exempelvis kyla. Den del av laddkedjan med lägst effekt avgör utfallet. Exempelvis klarar laddpunkten 22 kW men din bil endast 11 kW blir det praktiska utfallet 11 kW. Det kan också vara så att laddpunkter delar på effekt och effekten/hastigheten kan därför sjunka om någon kommer och laddar bredvid dig. Vid extra låg eller hög temperatur när luftkonditioneringen arbetar för fullt kan det vara som så att inte hela effekten går till laddning av batteriet.</p>

            <h2>Hur mycket förbrukar en elbil?</h2>
            <p>Precis som med förbränningsmotorer varierar förbrukningen mellan olika bilmodeller. Men tillskillnad från förbränningsmotorer är elmoter vädligt effektiva och påverkas därför i större utsträckning av olika faktorer, exempelvis väder, vind, lutning och körstil. Vintertid när AC:n kämpar för fullt med att värma upp bilen ökar förbrukningen. För enkel huvudräkning kan 2 kWh per mil användas som ett enkelt snittvärde.</p>

            <h2>Behöver jag ha med mig laddkabel?</h2>
            <p>För destinationsladdning behövs oftast en egen laddkabel, det är därför en god idé att alltid ha en liggande i bilen. För snabbladdning finns det (närmast) alltid en fast kabel och är inget man själv behöver (eller kan) ha med sig.</p>

            <h2>Går det att ladda gratis?</h2>
            <p>Ja, men de flesta ställen är inte gratis. I de fall laddning är gratis är det oftast destinationsladdning som erbjuds och till lite lägre effekt (3-7 kW). Med Laddifys många filteringsmöjligheter hittas enkelt laddstationer med gratis laddning.</p>
            
        </Layout>
    )
}

export default LaddskolaFragorSvarPage